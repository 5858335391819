<template>
    <el-container class="wrapper_policy">
        <el-card class="box-card">
            <div slot="header" class="clearfix">
                <span style="font-size: 20px; font-weight: bold">Соглашение об использовании материалов и сервисов интернет-сайта (пользовательское соглашение)</span>
                <el-button style="float: right; padding: 3px 0" type="text" >
                    <router-link to="/" class="logout_button">
                        Назад
                    </router-link>
                </el-button>
            </div>

                <el-container style="padding: 0 30px" direction="vertical">
                  <el-row class="privacy_row">
                    <el-col>
                      г. Москва, "01" сентября 2021 г.
                    </el-col>
                  </el-row>
                  <el-row class="privacy_row">
                        <el-col>
                          Настоящее  Соглашение  является  публичной офертой и определяет условия использования материалов  и сервисов, размещенных в информационной системе ЖилДомКом, расположенной на сайте в  сети Интернет по адресу: <a href="https://zhildomcom.ru">https://zhildomcom.ru</a> (<a href="https://жилодомком.рф">https://жилодомком.рф</a>), <a href="https://jlds.ru">https://jlds.ru</a>, <a href="https://jl-ds.ru">https://jl-ds.ru</a>  посетителями и пользователями данного интернет-сайта (далее – Сайт или Система).
                        </el-col>
                    </el-row>
                    <el-row class="privacy_row">
                        <el-col>
                          1. Общие условия
                        </el-col>
                    </el-row>
                    <el-row class="privacy_row">
                        <el-col>
                          1.1. Сайт является интеллектуальной собственностью (исключительное право принадлежит) ООО «Яковлев ЛегалТех» (105062, г. Москва, Б. Казенный пер., д.2, каб. 20 / ИНН 9709064392 / КПП 770901001 / ОГРН 1207700286067).
                        </el-col>
                    </el-row>
                    <el-row class="privacy_row">
                        <el-col>
                          1.2. Использование материалов и сервисов Сайта регулируется нормами действующего законодательства Российской Федерации.
                        </el-col>
                    </el-row>
                  <el-row class="privacy_row">
                        <el-col>
                          1.3. Для получения доступа к материалам Сайта Пользователю необходимо выполнить следующие действия:
                        </el-col>
                    <el-col>
                      - Связаться с Администрацией через форму обратной связи, размещенной в сети интернет по адресу https://jl-ds.ru/zhildomcom для подключения Дома к Системе.)
                    </el-col>
                    <el-col>
                      - В случае, если дом уже подключен к Системе  – подтвердить свою личность через Администратора сайта через средства связи с Администрацией;
                    </el-col>
                    <el-col>
                      - Указать в поле ввода свой номер телефона или адрес электронной почты, указанный при подтверждении личности, после чего авторизоваться, указав одноразовый код авторизации, полученный через соответствующее средство связи (СМС или электронная почта).
                    </el-col>
                    </el-row>
                    <el-row class="privacy_row">
                        <el-col>
                          1.4. Средства связи с Администрацией сайта:
                        </el-col>
                        <el-col>
<a href="https://jlds.ru/invite">https://jlds.ru/invite</a>
                        </el-col>
                      <el-row>
                        <a href="https://t.me/ZhilDomCom_bot">https://t.me/ZhilDomCom_bot</a>
                      </el-row>
                    </el-row>
                    <el-row class="privacy_row">
                        <el-col>
                          1.6. Получая доступ к материалам Сайта, Пользователь считается присоединившимся к настоящему Соглашению.
                        </el-col>
                    </el-row>
                  <el-row class="privacy_row">
                    <el-col>
                      1.7. Пользователь может использовать материалы Сайта и предоставляемые на Сайте сервисы следующим образом:
                    </el-col>
                    <el-col>
                      - принимать участие в общих собрания собственников недвижимости (ОСС) онлайн.
                    </el-col>
                    <el-col>
                      - получать информацию о проведенных, проводимых и/или объявленных ОСС.
                    </el-col>
                    <el-col>
                      - осуществлять иные права, предусмотренные Системой и действующим законодательством РФ.
                    </el-col>
                  </el-row>
                  <el-row class="privacy_row">
                    <el-col>
                      2. Обязательства Пользователя
                    </el-col>
                  </el-row>
                  <el-row class="privacy_row">
                    <el-col>
                      2.1. Пользователь соглашается не предпринимать действий и не оставлять комментарии и записи, которые могут рассматриваться как нарушающие российское законодательство или нормы международного права, в том числе в сфере интеллектуальной собственности, авторских и/или смежных прав, общепринятые нормы морали и нравственности, а также любых действий, которые приводят или могут привести к нарушению нормальной работы Сайта и сервисов Сайта.
                    </el-col>
                  </el-row>

                  <el-row class="privacy_row">
                    <el-col>
                      2.2. Использование материалов Сайта без согласия правообладателей не допускается.
                    </el-col>
                  </el-row>
                  <el-row class="privacy_row">
                    <el-col>
                      2.3. При цитировании материалов Сайта, включая охраняемые авторские произведения, ссылка на Сайт обязательна.
                    </el-col>
                  </el-row>
                  <el-row class="privacy_row">
                    <el-col>
                      2.4. Администрация Сайта не несет ответственности за посещение и использование им внешних ресурсов, ссылки на которые могут содержаться на Сайте.
                    </el-col>
                  </el-row>
                  <el-row class="privacy_row">
                    <el-col>
                      2.5. Администрация Сайта не несет ответственности и не имеет прямых или косвенных обязательств перед Пользователем в связи с любыми возможными или возникшими потерями или убытками, связанными с любым содержанием Сайта, регистрацией авторских прав и сведениями о такой регистрации, товарами или услугами, доступными на или полученными через внешние сайты или ресурсы либо иные контакты Пользователя, в которые он вступил, используя размещенную на Сайте информацию или ссылки на внешние ресурсы.
                    </el-col>
                  </el-row>
                  <el-row class="privacy_row">
                    <el-col>
                      2.6. Пользователь согласен с тем, что Администрация Сайта не несет какой-либо ответственности и не имеет каких-либо обязательств в связи с рекламой, которая может быть размещена на Сайте.
                    </el-col>
                  </el-row>

                  <el-row class="privacy_row">
                    <el-col>
                      3. Прочие условия
                    </el-col>
                  </el-row>
                  <el-row class="privacy_row">
                    <el-col>
                      3.1. Все возможные споры, вытекающие из настоящего Соглашения или связанные с ним, подлежат разрешению в соответствии с действующим законодательством Российской Федерации.
                    </el-col>
                  </el-row>
                  <el-row class="privacy_row">
                    <el-col>
                      3.2. Признание судом какого-либо положения Соглашения недействительным или не подлежащим принудительному исполнению не влечет недействительности иных положений Соглашения.
                    </el-col>
                  </el-row>
                  <el-row class="privacy_row">
                    <el-col>
                      3.3. Бездействие со стороны Администрации Сайта в случае нарушения кем-либо из Пользователей положений Соглашения не лишает Администрацию Сайта права предпринять позднее соответствующие действия в защиту своих интересов и защиту авторских прав на охраняемые в соответствии с законодательством материалы Сайта, которые могут выражаться в любых предусмотренных действующим законодательством действиях.
                    </el-col>
                  </el-row>
                  <el-row class="privacy_row">
                    <el-col>
                      3.4. Администрация Сайта вправе в любое время в одностороннем порядке изменять условия настоящего Соглашения. Такие изменения вступают в силу по истечении 2 (двух) дней с момента размещения новой версии Соглашения на сайте. При несогласии Пользователя с внесенными изменениями он обязан отказаться от доступа к Сайту, прекратить использование материалов и сервисов Сайта.
                    </el-col>
                  </el-row>
                  <el-row class="privacy_row">
                    <el-col>
                      3.5. Переходя по ссылке <a href="https://zhildomcom.ru">https://zhildomcom.ru</a>, а также продолжая пользоваться Сайтом, Пользователь подтверждает, что принимает условия настоящего Соглашения, а также Политики конфиденциальности Сайта, являющейся неотъемлемой частью настоящего Соглашения.
                    </el-col>
                  </el-row>


                  <el-row class="privacy_row">
                    <el-col>
                    </el-col>
                  </el-row>
                  <el-row class="privacy_row">
                    <el-col>
                    </el-col>
                  </el-row>

                </el-container>
        </el-card>

    </el-container>
</template>

<script>
export default {
    name: "AboutCookie"
}
</script>

<style scoped lang="scss">
@import "~@/theme.scss";

.privacy_row {
    margin-top: 15px;
}

.wrapper_policy {
    min-height: 100vh;
    padding: 40px;
    .logout_button {
        color: $dom-light-blue;
        text-decoration: none;
        font-size: 16px;
    }
    .logout_button:hover {
        text-decoration: underline;
    }
    .el-header {
        padding: 0;
    }
}
</style>
